export const imgStyle = {
  height: "100%",
  width: "100%",
};

export const qrCodeStyle = {
  position: "absolute",
  top: "53%",
  left: "42%",
  //   transform: "translate(-50%, -50%)",
};
