import React from "react";

function Error() {
  return (
    <>
      <div className="text-center" style={{ marginTop: "75px" }}>
        <h1>Error 404</h1>
        <h3>Oops! This page does not exist.</h3>
      </div>
    </>
  );
}

export default Error;
