import { useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import Paginator from "../Paginator";
import { BsArrowCounterclockwise, BsSearch } from "react-icons/bs";
import StateDataService from "../../services/state.services";

function ViewStates() {
  const [states, setStates] = useState([]);
  const [originalStates, setOriginalStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [statesPerPage] = useState(10);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    setLoading(true);
    const data = await StateDataService.getAllStates();
    console.log("Loaded states: " + data.length);
    setStates(data);
    setOriginalStates(data);
    setLoading(false);
  };

  const updateList = (e) => {
    let previousStates = states;
    let filteredStates = previousStates.filter((state) =>
      state.name.toLowerCase().includes(e)
    );

    if (e === "" || e === " ") {
      filteredStates = originalStates;
    }

    if (filteredStates.length > 0) {
      setStates(filteredStates);
    } else {
      setStates(originalStates);
    }
  };

  const indexOfLastState = currentPage * statesPerPage;
  const indexOfFirstState = indexOfLastState - statesPerPage;
  const currentStates = states.slice(indexOfFirstState, indexOfLastState);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div
        className="list"
        style={{ width: "75%", left: "10%", marginTop: "0" }}
      >
        <div className="mb-2">
          <Row>
            <Col xs="auto">
              <Button variant="info" size="sm" onClick={getStates}>
                <BsArrowCounterclockwise style={{ marginRight: "3px" }} />
                Refresh List
              </Button>
            </Col>
            <Col xs={8}>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id="basic-addon1">
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search lot..."
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    updateList(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        {loading && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Table className="text-center shadow-sm" striped hover size={"sm"}>
          <thead>
            <tr>
              <th>State</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {currentStates
              .sort((a, b) => {
                return a.name - b.name;
              })
              .map((doc) => {
                return (
                  <tr key={doc._id}>
                    <td>{doc.name}</td>
                    <td>{doc.stateCode}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Paginator
          itemsPerPage={statesPerPage}
          totalItems={states.length}
          paginate={paginate}
          currentPage={currentPage}
        />
        <div>
          {states.length > statesPerPage && (
            <Paginator
              itemsPerPage={statesPerPage}
              totalItems={states.length}
              paginate={paginate}
              currentPage={currentPage}
              visible={true}
              isDataItems={false}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ViewStates;
