import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Spinner,
  InputGroup,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { BsArrowCounterclockwise, BsSearch } from "react-icons/bs";
import ItemDataService from "../services/item.services";
import ItemSelectModal from "./ItemSelectModal";
import ImportItemsModal from "./admin/modals/ImportItemsModal";
import Paginator from "./Paginator";
import LotDataService from "../services/lot.services";
import ItemDetailsModal from "./ItemDetailsModal";
import ExportItemsButton from "../components/admin/buttons/ExportItemsButton.js";
import BuildingDataService from "../services/building.services";
import DeleteItemsButton from "./admin/buttons/DeleteItemsButton";
import Select from "react-select";

const ItemList = ({ admin, modal, buildingsOnly }) => {
  const [originalItems, setOriginalItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [lots, setLots] = useState([]);
  const [pagination, setPagination] = useState(true);

  const [company, setCompany] = useState("Leonard");

  useEffect(() => {
    getAllItems();
    getLots();
  }, []);

  const getLots = async () => {
    setLoading(true);
    const data = await LotDataService.getAllLots();
    console.log("Loaded lots listItem: " + data.length);
    setLots(data);
    setLoading(false);
  };

  const [lotNumber, setLotNumber] = useState();
  const [zoneNumber, setZoneNumber] = useState();

  const getAllItems = async () => {
    setLoading(true);
    const data = await ItemDataService.getAllItems();
    setItems(data);
    setOriginalItems(data);

    if (buildingsOnly) {
      setItems(items.filter((item) => item.type.includes("Building")));
      setOriginalItems(items.filter((item) => item.type.includes("Building")));
    }

    setLoading(false);
  };

  function getCompanyByItem(item) {
    if (!item.company || item.company === null) {
      return "Leonard";
    }

    return item.company;
  }

  const updateItemList = (e) => {
    let previousItems = items;
    let filteredItems = previousItems.filter(
      (item) =>
        item.name.toLowerCase().includes(e.toLowerCase()) &&
        getCompanyByItem(item).includes(company)
    );

    if (e.length < 1) {
      filteredItems = originalItems.filter((item) =>
        getCompanyByItem(item).includes(company)
      );
    }

    if (filteredItems.length > 0) {
      setItems(
        filteredItems.filter((item) => getCompanyByItem(item).includes(company))
      );
      setCurrentPage(1);
    } else {
      setItems(
        originalItems.filter((item) => getCompanyByItem(item).includes(company))
      );
    }

    updateItemType();
  };

  const updateItemType = (e) => {
    let type = e;
    let filteredItems = originalItems;

    if (type === "0") {
      setItems(originalItems);
    }

    if (type === "Building") {
      setItems(filteredItems.filter((item) => item.type.includes("Building")));
    }

    if (type === "Trailers") {
      setItems(filteredItems.filter((item) => !item.type.includes("Building")));
    }
  };

  function getLotByNumber(lotNumber) {
    return lots.filter((lot) => lot.number === lotNumber)[0];
  }

  const updateCompany = (e) => {
    let company = e;
    let filteredItems = originalItems;
    setCompany(company);
    setCurrentPage(1);

    if (company === "0" || company === 0) {
      setItems(originalItems);
    }

    if (company === "Leonard") {
      setItems(filteredItems.filter((item) => !item.company?.includes("Cook")));
    }

    if (company === "Cook") {
      setItems(filteredItems.filter((item) => item.company?.includes("Cook")));
      // setLotNumber(getLotByNumber("Cook").number);
      // setZoneNumber(getLotByNumber("Cook").zone);
    }
  };

  function getFilterNameByType(e) {
    let filteredItem = BuildingDataService.getAllTypes().filter(
      (type) => type.type === e
    )[0];
    return !filteredItem ? null : filteredItem?.name;
  }

  const updateBuildingType = (e) => {
    let buildingType = e;
    let filteredItems = originalItems.filter((item) =>
      getCompanyByItem(item).includes(company)
    );
    let filterName = getFilterNameByType(e);

    if (buildingType === "0" || buildingType === 0 || filterName === null) {
      setItems(
        originalItems.filter((item) => getCompanyByItem(item).includes(company))
      );
      return;
    }

    setItems(
      filteredItems.filter(
        (item) =>
          item.name.includes(filterName) &&
          getCompanyByItem(item).includes(company)
      )
    );
  };

  // const deleteBad = () => {
  //   let badItems = items.filter((item) => !typeList.includes(item.type));
  //   badItems.forEach((item) => deleteHandler(item.id));
  //   console.log("Deleted bad items: " + badItems.size);
  // };

  const deleteHandler = async (name) => {
    await ItemDataService.deleteItem(name);
    // alert("You successfully deleted the item: " + id);
    console.log("Deleted item: " + name);
    getAllItems();
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div
        className="list"
        style={modal && { width: "90%", left: "5%", marginTop: "0" }}
      >
        <div>
          <Row>
            <Col xs="auto">
              <Button
                variant="info"
                size="sm"
                onClick={getAllItems}
                style={{ minHeight: "38px" }}
              >
                <BsArrowCounterclockwise style={{ marginRight: "3px" }} />
                Refresh
              </Button>
            </Col>
            <Col xs={modal ? 3 : 5}>
              <InputGroup
                className="mb-3"
                size="sm"
                style={modal ? {} : { marginLeft: "0px" }}
              >
                <InputGroup.Text id="basic-addon1">
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search item number..."
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    updateItemList(e.target.value);
                  }}
                  style={{ minHeight: "38px" }}
                />
              </InputGroup>
            </Col>
            <Col xs={modal ? "auto" : 1} style={{ width: "150px" }}>
              <Form.Group className="mb-4" id="lot-filter">
                <Select
                  noOptionsMessage={() => "No Lots Found"}
                  placeholder="Lot"
                  onChange={(e) => {
                    setLotNumber(lots[e.value].number);
                    setZoneNumber(lots[e.value].zone);
                  }}
                  options={lots
                    .sort((a, b) => a.number - b.number)
                    .map((lot, index) => ({
                      key: lot._id,
                      value: index,
                      label: lot.number,
                    }))}
                />
              </Form.Group>
            </Col>

            <Col xs={modal ? "auto" : 2} style={{ width: "180px" }}>
              <Form.Group className="mb-4" id="type-filter">
                <Select
                  placeholder="Type"
                  onChange={(e) => updateItemType(e.value)}
                  options={[
                    { key: "0", value: "0", label: "All" },
                    {
                      key: "building",
                      value: "Building",
                      label: "Buildings",
                    },
                    { key: "trailers", value: "Trailers", label: "Trailers" },
                  ]}
                />
              </Form.Group>
            </Col>
            <Col xs={modal ? "auto" : 1} style={{ width: "200px" }}>
              <Form.Group className="mb-4" id="company-filter">
                <Select
                  placeholder="Building Type"
                  options={[
                    { key: "0", value: "0", label: "All" },
                    ...BuildingDataService.getLeonardTypes().map((type) => ({
                      key: type.name,
                      value: type.type,
                      label: type.type,
                    })),
                    ...BuildingDataService.getCookTypes().map((type) => ({
                      key: type.name,
                      value: type.type,
                      label: type.type,
                    })),
                  ]}
                  onChange={(e) => updateBuildingType(e.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={modal ? "auto" : 2} style={{ width: "150px" }}>
              <Form.Group className="mb-4" id="company-filter">
                <Select
                  placeholder="Company"
                  options={[
                    { key: "0", value: "0", label: "All" },
                    { key: "leonard", value: "Leonard", label: "Leonard" },
                    { key: "cook", value: "Cook", label: "Cook" },
                  ]}
                  onChange={(e) => updateCompany(e.value)}
                />
              </Form.Group>
            </Col>
            {admin && (
              <>
                <Row style={{ marginBottom: "15px" }}>
                  <Col xs="auto">
                    <ImportItemsModal items={items} />
                    <ExportItemsButton data={items} />
                    <DeleteItemsButton />
                    <Button
                      size="sm"
                      variant="warning"
                      href={require("../files/SimpleSignTemplate.xlsx")}
                      download="SimpleSignTemplate"
                      style={{ marginLeft: "5px" }}
                    >
                      Download Template
                    </Button>
                  </Col>
                  <Col xs={modal ? "auto" : 1}>
                    <p>Total Items: {items.length}</p>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </div>
        {loading && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!modal && (
          <p style={{ margin: "0", marginBottom: "20px", textAlign: "center" }}>
            If there are any items that need to be added please email{" "}
            <a href="mailto:trailerteam@leonardusa.com?subject=Simple Signs Missing Item">
              trailerteam@leonardusa.com
            </a>{" "}
            or{" "}
            <a href="mailto:acraft@leonardusa.com?subject=Simple Signs Missing Item">
              acraft@leonardusa.com
            </a>
            .
          </p>
        )}
        <Table
          className="shadow-sm"
          striped
          hover
          bordered
          responsive="lg"
          size={"lg"}
        >
          <thead>
            <tr>
              <th>Item</th>
              <th>Size</th>
              <th>Type</th>
              <th>Building Type</th>
              <th>Company</th>
              {admin && <th>Date Added</th>}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((doc, index) => {
              return (
                <tr key={doc._id}>
                  <td>{doc.name}</td>
                  <td>{doc.size}</td>
                  <td>{doc.type}</td>
                  <td>
                    {!BuildingDataService.getBuildingTypeByItem(doc)
                      ? ""
                      : BuildingDataService.getBuildingTypeByItem(doc).type}
                  </td>
                  <td>{doc.company !== "Cook" ? "Leonard" : "Cook"}</td>
                  {admin && (
                    <td>{doc.dateAdded.replace(",", "").split(" ")[0]}</td>
                  )}
                  <td>
                    <ItemSelectModal
                      inModal={modal}
                      item={doc}
                      zoneNumber={zoneNumber}
                      lot={getLotByNumber(lotNumber)}
                      company={company}
                      buildingType={
                        !BuildingDataService.getBuildingTypeByItem(doc)
                          ? ""
                          : BuildingDataService.getBuildingTypeByItem(doc).type
                      }
                    />
                    <ItemDetailsModal item={doc} admin={admin} />
                    {/* check if the user is admin before allowing the delete button */}
                    {admin && (
                      <>
                        <Button
                          variant="danger"
                          size="sm"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => deleteHandler(doc.name)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <Paginator
            visible={pagination}
            itemsPerPage={itemsPerPage}
            totalItems={items.length}
            paginate={paginate}
            currentPage={currentPage}
            isDataItems={true}
          />
        </div>
      </div>
    </>
  );
};

export default ItemList;
