import { Pagination } from "react-bootstrap";

const Paginator = ({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
  visible,
  isDataItems,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const createPaginationItem = (i) => {
    return (
      <li
        key={i + Math.random()}
        className={currentPage === i ? "page-item active" : "page-item"}
      >
        <a onClick={() => paginate(i)} className="page-link">
          {i}
        </a>
      </li>
    );
  };

  const paginationItems = [];
  paginationItems.push(
    <Pagination.First key="first" onClick={(e) => paginate(1)} />
  );
  paginationItems.push(
    <Pagination.Prev
      key="previous"
      disabled={currentPage === 1}
      onClick={(e) => paginate(currentPage - 1)}
    />
  );
  if (isDataItems) {
    if (currentPage > 1 && totalPages > 1) {
      if (currentPage === totalPages && currentPage - 2 > 0) {
        paginationItems.push(
          <Pagination.Item
            key={Math.random()}
            onClick={(e) => paginate(currentPage - 2)}
          >
            {currentPage - 2}
          </Pagination.Item>
        );
      }
      paginationItems.push(
        <Pagination.Item
          key={Math.random()}
          onClick={(e) => paginate(currentPage - 1)}
        >
          {currentPage - 1}
        </Pagination.Item>
      );
    }
    paginationItems.push(
      <Pagination.Item
        key={Math.random()}
        onClick={(e) => paginate(currentPage)}
        active={true}
      >
        {currentPage}
      </Pagination.Item>
    );
    if (totalPages > currentPage) {
      paginationItems.push(
        <Pagination.Item
          key={Math.random()}
          onClick={(e) => paginate(currentPage + 1)}
        >
          {currentPage + 1}
        </Pagination.Item>
      );
    }
    if (currentPage === 1 && totalPages > 2) {
      paginationItems.push(
        <Pagination.Item
          key={Math.random()}
          onClick={(e) => paginate(currentPage + 2)}
        >
          {currentPage + 2}
        </Pagination.Item>
      );
    }
  } else {
    pageNumbers.forEach((value, index) => {
      paginationItems.push(
        <Pagination.Item
          key={value}
          active={currentPage === value}
          onClick={(e) => paginate(value)}
        >
          {value}
        </Pagination.Item>
      );
    });
  }
  paginationItems.push(
    <Pagination.Next
      key="next"
      onClick={(e) => paginate(currentPage + 1)}
      disabled={currentPage === totalPages}
    />
  );

  paginationItems.push(
    <Pagination.Last key="last" onClick={(e) => paginate(totalPages)} />
  );

  return (
    <>
      {visible && (
        <nav>
          <ul className="pagination pagination-sm">{paginationItems}</ul>
        </nav>
      )}
    </>
  );
};
export default Paginator;
