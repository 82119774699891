import React, { useState } from "react";
import { Button, Modal, Form, Row, Col, Alert } from "react-bootstrap";
import ItemDataService from "../services/item.services";

function ItemDetailsModal({ item, admin }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    resetFields();
  };
  const handleShow = () => {
    setShow(true);
  };

  let typeList = ["Building", "Aluminum", "Tow", "Cargo", "Dump", "Utility"];

  const [name, setName] = useState(item.name);
  const [type, setType] = useState(item.type);
  const [size, setSize] = useState(item.size);

  // Cash pricing
  const [zone1Cash, setZone1Cash] = useState(item.zone1Cash);

  // Card Pricing
  const [zone1Card, setZone1Card] = useState(item.zone1Card);

  // MSRP pricing
  const [zone1Msrp, setZone1Msrp] = useState(item.zone1Msrp);

  // Monthly pricing
  const [zone1Monthly, setZone1Monthly] = useState(item.zone1Monthly);

  const [gvwr, setGvwr] = useState(item.gvwr);

  const [company, setCompany] = useState(item.company);

  const [message, setMessage] = useState({ error: false, message: "" });

  let lastUpdated =
    new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();

  const newItem = {
    name,
    type,
    size,
    zone1Cash,
    zone1Card,
    zone1Msrp,
    zone1Monthly,
    gvwr,
    company,
    lastUpdated,
  };

  const updateItem = async () => {
    if (!typeList.includes(newItem.type)) {
      alert("The item type must be one of the following: " + typeList);
      return;
    }
    console.log("Current Item");
    console.log(item);
    console.log("New Item");
    console.log(newItem);
    try {
      await ItemDataService.updateItem(item.name, newItem);
      setMessage({
        error: false,
        message: "You have updated item: " + item.name,
      });
    } catch (e) {
      setMessage({ error: true, message: e.message });
    }
  };

  const resetFields = () => {
    setMessage(false, "");
  };

  return (
    <>
      <Button
        variant="warning"
        size="sm"
        style={{ marginLeft: "5px" }}
        onClick={handleShow}
      >
        Details
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Item: {item.name}</Modal.Title>
        </Modal.Header>
        {message?.message && (
          <Alert
            style={{ margin: "25px" }}
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.message}
          </Alert>
        )}
        <Modal.Body>
          <Form>
            <h4 className="text-center">Information</h4>
            {admin && <p>Added by: {item.addedBy}</p>}
            <Row>
              <Col>
                <Form.Group className="mb-4" id="item-name">
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item name"
                    value={name}
                    disabled={!admin}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4" id="item-type">
                  <Form.Label>Item Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item type"
                    value={type}
                    disabled={!admin}
                    onChange={(e) => setType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Item Size</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item size"
                    value={size}
                    disabled={!admin}
                    onChange={(e) => setSize(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {type !== "Building" && (
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>Item GVWR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Gross vehicle weight rating"
                      value={gvwr}
                      disabled={!admin}
                      required
                      onChange={(e) => setGvwr(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              )}
              {type === "Building" && (
                <Col xs={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>Company</Form.Label>
                    <Form.Select
                      defaultValue={company}
                      onChange={(e) => setCompany(e.target.value)}
                      disabled={!admin}
                    >
                      <option key="0" value="0">
                        Company
                      </option>
                      <option key="leonard" value="Leonard">
                        Leonard
                      </option>
                      <option key="cook" value="Cook">
                        Cook
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <h4 className="text-center">Pricing</h4>
            {/* Cash Pricing */}
            <Row>
              <Col xs={1}>
                <p style={{ marginTop: "5px" }}>Price</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-cash">
                  <Form.Control
                    type="text"
                    placeholder="cash"
                    value={zone1Cash}
                    disabled={!admin}
                    onChange={(e) => setZone1Cash(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Card Pricing */}
            {/* <Row>
              <Col xs={1}>
                <p style={{ marginTop: "5px" }}>Card</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-card">
                  <Form.Control
                    type="text"
                    placeholder="card"
                    value={zone1Card}
                    disabled={!admin}
                    onChange={(e) => setZone1Card(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            {/* MSRP Pricing */}
            {/* <Row>
              <Col xs={1}>
                <p style={{ marginTop: "5px" }}>MSRP</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-msrp">
                  <Form.Control
                    type="text"
                    placeholder="MSRP"
                    value={zone1Msrp}
                    disabled={!admin}
                    onChange={(e) => setZone1Msrp(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            {/* Monthly Pricing */}
            <Row>
              <Col xs={1}>
                <p style={{ marginTop: "5px" }}>Monthly</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-monthly">
                  <Form.Control
                    type="text"
                    placeholder="Monthly"
                    value={zone1Monthly}
                    disabled={!admin}
                    onChange={(e) => setZone1Monthly(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {admin && (
              <Button variant="success" onClick={updateItem}>
                Update
              </Button>
            )}

            <Button
              variant="secondary"
              onClick={handleClose}
              style={{ marginLeft: "7px" }}
            >
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemDetailsModal;
