import React, { useEffect, useState } from "react";
import {
  imgStyle,
  sizeStyle,
  sizeTextStyle,
  qrCodeImageStyle,
  qrCodeStyle,
  typeStyle,
  typeTextStyle,
  monthlyStyle,
  monthlyTextStyle,
  msrpStyle,
  msrpTextStyle,
  cardStyle,
  cardTextStyle,
  cashStyle,
  cashTextStyle,
  lotStyle,
  petCoopLotStyle,
  lotTextStyle,
  itemStyle,
  itemTextStyle,
  typeSmallTextStyle,
  typeSmallStyle,
  cookItemStyle,
  cookBigItemStyle,
  cookItemTextStyle,
  cookBigItemTextStyle,
  coopCashPriceStyle,
  coopCashPriceTextStyle,
  coopMsrpStyle,
  coopMsrpTextStyle,
  coopCardPriceStyle,
  coopCardPriceTextStyle,
  dateStyle,
  dateTextStyle,
  datePriceStyle,
} from "../styles/BuildingStyleSheet";
import BuildingDataService from "../services/building.services";
import LotDataService from "../services/lot.services";
import StateDataService from "../services/state.services";

function currencyFormat(num) {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const BuildingSignForm = React.forwardRef((props, ref) => {
  const getPageMargins = () => {
    return `
    @page {
        margin: 0px !important;
        * {
           page-break-inside: avoid;
        }
       
    } 
    @media print {
        @page {
            size: landscape !important;
            page-break-inside: avoid;
        }
    }`;
  };

  const [type, setType] = useState("");
  const [qrCode, setQRCode] = useState("");

  const getRegionId = (state, lot) => {
    // TEMPLATE: 001102
    let stateCode = state?.stateCode;
    let storeNumber = lot?.number;

    return `${stateCode}${storeNumber}`;
  };

  const fetchQrCode = async () => {
    const state = await StateDataService.getState(props.lot?.stateId).catch(
      (err) => console.error(err)
    );
    const regionId = getRegionId(state, props.lot);

    if (qrCode) {
      let url = decodeURIComponent(qrCode);
      let params = new URLSearchParams(url);
      let sku = params.get("sku");
      let region = params.get("region_id");

      if (sku == props.itemName && region == regionId) {
        console.log("QR code already exists, skipping...");
        return;
      }
    }

    try {
      console.log("Fetching QR code...");

      const response = await LotDataService.generateQRCodeURL(
        props.lot?.brand,
        props.lot?.number,
        regionId,
        props.itemName,
        props.cardPrice?.replace(",", "")?.replace("$", "")?.split(".")[0]
      );

      console.log("QR code response:", decodeURIComponent(response.url));

      setQRCode(response.url);
    } catch (error) {
      console.log("Error fetching QR code:", error);
    }
  };

  useEffect(() => {
    determineType();
    // fetchQrCode();
  }, [props]);

  // Prefixes: CONDO, HIRK, HMP2, LNTO, MBY1, MNBE, MNSB, PDM1, PLBG, PLCC, PLTE, PLTG, PLTS, PLTW
  // Suggested name: DOG KENNEL, SALTBOX, VINYL A-FRAME, LEAN TO, BARN, MINI BARN, PLAYHOUSE, WOOD A-FRAME, PLAYHOUSE, PLAYHOUSE, METAL ECONOMY, GOLF CART READY, PREMIUM METAL, METAL SIDED WOOD FRAMED
  function determineType() {
    let currentType = props.type;
    let itemName = props.itemName;

    if (props.company !== "Cook") {
      if (itemName.toUpperCase().includes("CONDO")) {
        setType("Dog Kennel");
      } else if (itemName.toUpperCase().includes("HIRK")) {
        setType("Saltbox");
      } else if (itemName.toUpperCase().includes("HMP2")) {
        setType("Vinyl A-Frame");
      } else if (itemName.toUpperCase().includes("LNTO")) {
        setType("Lean To");
      } else if (itemName.toUpperCase().includes("LNTM")) {
        setType("Metal Framed Lean To");
      } else if (itemName.toUpperCase().includes("MBY1")) {
        setType("Barn");
      } else if (itemName.toUpperCase().includes("MNBE")) {
        setType("Mini Barn");
      } else if (itemName.toUpperCase().includes("MNSB")) {
        setType("Playhouse");
      } else if (itemName.toUpperCase().includes("PDM1")) {
        setType("Wood A-Frame");
      } else if (itemName.toUpperCase().includes("PLBG")) {
        setType("Playhouse");
      } else if (itemName.toUpperCase().includes("PLCC")) {
        setType("Playhouse");
      } else if (itemName.toUpperCase().includes("PLTE")) {
        setType("Metal Economy");
      } else if (itemName.toUpperCase().includes("PLTG")) {
        setType("Golf Cart Ready");
      } else if (itemName.toUpperCase().includes("PLTS")) {
        setType("Premium Metal");
      } else if (itemName.toUpperCase().includes("PLTW")) {
        setType("Metal Sided Wood Framed");
      } else if (itemName.toUpperCase().includes("RITZ")) {
        setType("The Ritz");
      } else if (itemName.toUpperCase().includes("OSCR")) {
        setType("The Oscar");
      } else if (
        itemName.toUpperCase().includes("BARN") &&
        itemName.toUpperCase().includes("000")
      ) {
        setType("Barn");
      } else if (
        itemName.toUpperCase().includes("BARN") &&
        itemName.toUpperCase().includes("001")
      ) {
        setType("Lofted Barn");
      } else if (
        itemName.toUpperCase().includes("UTLY") &&
        itemName.toUpperCase().includes("000")
      ) {
        setType("Utility");
      } else if (
        itemName.toUpperCase().includes("UTLY") &&
        itemName.toUpperCase().includes("001")
      ) {
        setType("Lofted Utility");
      } else if (itemName.toUpperCase().includes("GRDN")) {
        setType("Garden Shed");
      } else if (itemName.toUpperCase().includes("TOOL")) {
        setType("Tool Shed");
      } else if (itemName.toUpperCase().includes("HNDY")) {
        setType("Lofted Barn Handyman");
      } else if (itemName.toUpperCase().includes("BGAR")) {
        setType("Lofted Barn Garage");
      } else if (itemName.toUpperCase().includes("UGAR")) {
        setType("Utility Garage");
      } else if (itemName.toUpperCase().includes("SLIM")) {
        setType("Slim Shed");
      } else if (
        itemName.toUpperCase().includes("COOP") ||
        itemName.toUpperCase().includes("MBYC")
      ) {
        setType("Coop");
      } else if (
        itemName.includes("CNDO") ||
        itemName.includes("DOG") ||
        itemName.includes("CAT")
      ) {
        setType("Pet");
      } else if (itemName.includes("PLANTER")) {
        setType("Planter");
      }
    } else {
      console.log("Prop Building Type: " + props.buildingType);
      setType(props.buildingType);

      if (itemName.includes("Coop")) {
        setType("Chicken Coop");
      }

      // if (itemName.includes("Barn")) {
      //   setType("Barn");
      // } else if (itemName.includes("Utility")) {
      //   setType("A-Frame");
      // } else if (itemName.includes("Garden Shed")) {
      //   setType("Salt Box");
      // } else if (itemName.includes("Tool Shed")) {
      //   setType("A-Frame");
      // } else if (itemName.includes("Garage")) {
      //   setType("A-Frame");
      // } else if (itemName.includes("Slim Shed")) {
      //   setType("Lean To");
      // }
    }

    if (itemName.toUpperCase().includes("PLANTER")) {
      setType("Planter");
    }

    console.log("Item Name: " + itemName);
    console.log("Type: " + type);
  }

  const cookBuildings = [
    "BARN",
    "UTLY",
    "GRDN",
    "TOOL",
    "HNDY",
    "BGAR",
    "UGAR",
    "SLIM",
  ];

  function determineFormStyle() {
    if (props.company === "Cook") {
      if (type === "Planter") {
        return require("../images/Cook-Blank-Simple-Sign-Form-Pet-Houses.png");
      }

      return require("../images/Cook-Building-Sign.png");
    }

    if (type === "Coop") {
      return require("../images/Leonard-Chicken-Coop-Sign.png");
    }

    if (type === "Pet") {
      return require("../images/Simple-Sign-Form-Pet-Houses.png");
    }

    if (type === "Planter") {
      return require("../images/Simple-Sign-Form-Planter.png");
    }

    const itemName = props.itemName.split("-")[1];
    let leonardCookStyle = cookBuildings.includes(itemName);
    if (props.company !== "Cook" && leonardCookStyle) {
      return require("../images/Cook-Building-Leonard-Sign.png");
    }

    return require("../images/Leonard-Building-Sign.png");
  }

  function getForm() {
    let cashPriceMoreThan1 = props.cashPrice.toString().replace("$", "") > 1;
    let cardPriceMoreThan1 = props.cardPrice.toString().replace("$", "") > 1;

    if (props.formType === "everything") {
      return (
        <>
          <style>{getPageMargins()}</style>
          <div
            ref={ref}
            className="print-form"
            style={{
              margin: "15px",
              fontFamily: "Arial, sans-serif",
              whiteSpace: "nowrap",
            }}
          >
            <img
              // src={require("../images/blank-bob-simple-sign.png")}
              src={determineFormStyle()}
              alt="Building Simple Sign"
              style={imgStyle}
            />
            <div className="size" style={sizeStyle}>
              <p style={sizeTextStyle}>{props.size}</p>
            </div>

            {/* Building Type Display if not a Coop  or a Pet House */}
            {type !== "Coop" && type !== "Pet" && type !== "Planter" && (
              <div
                className="type"
                style={
                  type.length >= 13 || type.toLowerCase().includes("premium")
                    ? typeSmallStyle
                    : typeStyle
                }
              >
                <h2
                  style={
                    type.length >= 13 || type.toLowerCase().includes("premium")
                      ? typeSmallTextStyle
                      : typeTextStyle
                  }
                >
                  {type}
                </h2>
              </div>
            )}

            {/* Monthly Price Display if not a Coop or Pet House */}
            {type !== "Coop" && type !== "Pet" && type !== "Planter" && (
              <div className="monthly" style={monthlyStyle}>
                <h3 style={monthlyTextStyle}>
                  {currencyFormat(props.monthly.split(".")[0].replace("$", ""))}
                </h3>
              </div>
            )}

            {/* Coop Cash Display */}
            {type === "Coop" && (
              <div className="coopCashPrice" style={coopCashPriceStyle}>
                <h3 style={coopCashPriceTextStyle}>
                  {currencyFormat(
                    props.cashPrice.split(".")[0].replace("$", "")
                  )}
                </h3>
              </div>
            )}

            {/* Pet House Cash Display */}
            {type === "Pet" && (
              <div className="coopCashPrice" style={coopCashPriceStyle}>
                <h3 style={coopCashPriceTextStyle}>
                  {currencyFormat(
                    props.cashPrice.split(".")[0].replace("$", "")
                  )}
                </h3>
              </div>
            )}

            {/* Planter Cash Display */}
            {type === "Planter" && (
              <div className="coopCashPrice" style={coopCashPriceStyle}>
                <h3 style={coopCashPriceTextStyle}>
                  {props.company === "Cook" &&
                    currencyFormat(props.msrp.split(".")[0].replace("$", ""))}
                  {props.company !== "Cook" &&
                    currencyFormat(
                      props.cashPrice.split(".")[0].replace("$", "")
                    )}
                </h3>
              </div>
            )}

            {/* MSRP and Card Price if not a Cook building, Coop, or Pet House */}
            {props.company !== "Cook" &&
              type !== "Coop" &&
              type !== "Pet" &&
              type !== "Planter" && (
                <>
                  {/* <div className="msrp" style={msrpStyle}>
                  <p style={msrpTextStyle}>${props.msrp.replace("$", "")}</p>
                </div> */}
                  {/* <div className="cardPrice" style={cardStyle}>
                  <p style={cardTextStyle}>
                    $
                    {props.cardPrice
                      .toString()
                      .replace("$", "")
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div> */}
                </>
              )}

            {/* {type === "Coop" && (
              <div className="msrp" style={coopMsrpStyle}>
                <p style={coopMsrpTextStyle}>
                  $
                  {currencyFormat(
                    props.msrp
                      .toString()
                      .replace("$", "")
                      .replace(/[^0-9\.-]+/g, "")
                  )}
                </p>
              </div>
            )} */}

            {/* {type === "Pet" && (
              <div className="msrp" style={coopMsrpStyle}>
                <p style={coopMsrpTextStyle}>
                  ${currencyFormat(props.msrp.toString().replace("$", ""))}
                </p>
              </div>
            )} */}

            {/* Cash Price Display if it's not a Coop or Pet House */}
            {type !== "Coop" && type !== "Pet" && type !== "Planter" && (
              <div className="cashPrice" style={cashStyle}>
                <p style={cashTextStyle}>
                  $
                  {props.company !== "Cook"
                    ? currencyFormat(
                        props.cashPrice.toString().replace("$", "")
                      )
                    : currencyFormat(props.msrp.toString().replace("$", ""))}
                </p>
              </div>
            )}

            {/* Card Price Display for Coops */}
            {/* {type === "Coop" && (
              <div className="cashPrice" style={coopCardPriceStyle}>
                <p style={coopCardPriceTextStyle}>
                  $
                  {props.company !== "Cook"
                    ? currencyFormat(
                        props.cardPrice.toString().replace("$", "")
                      )
                    : currencyFormat(props.cardPrice.toString())}
                </p>
              </div>
            )} */}

            {/* Card Price Display for Pet House */}
            {/* {type === "Pet" && (
              <div className="cashPrice" style={coopCardPriceStyle}>
                <p style={coopCardPriceTextStyle}>
                  $
                  {props.company !== "Cook"
                    ? currencyFormat(
                        props.cardPrice.toString().replace("$", "")
                      )
                    : currencyFormat(props.cardPrice.toString())}
                </p>
              </div>
            )} */}

            {/* Displays Lot Text if not a Cook building */}
            {props.lot?.number !== "Cook" &&
              type !== "Coop" &&
              type !== "Pet" &&
              type !== "Planter" && (
                <div className="lotText" style={lotStyle}>
                  <p style={lotTextStyle}>{props.lot?.number}</p>
                </div>
              )}

            {type === "Coop" && (
              <div
                style={{
                  position: "absolute",
                  top: "664px",
                  left: "35%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p style={lotTextStyle}>{props.lot?.number}</p>
              </div>
            )}

            {type === "Pet" && (
              <div
                style={{
                  position: "absolute",
                  top: "656px",
                  left: "35%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p style={lotTextStyle}>{props.lot?.number}</p>
              </div>
            )}

            {type === "Planter" && (
              <div
                style={{
                  position: "absolute",
                  top: "656px",
                  left: "35%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p style={lotTextStyle}>{props.lot?.number}</p>
              </div>
            )}

            {/* Model Text */}
            {type !== "Coop" && type !== "Pet" && type !== "Planter" && (
              <div
                className="modelText"
                style={
                  props.company === "Cook"
                    ? props.itemName.length > 18
                      ? cookBigItemStyle
                      : cookItemStyle
                    : itemStyle
                }
              >
                <p
                  style={
                    props.company === "Cook"
                      ? props.itemName.length > 18
                        ? cookBigItemTextStyle
                        : cookItemTextStyle
                      : itemTextStyle
                  }
                >
                  {props.itemName}
                </p>
              </div>
            )}

            {type === "Coop" && (
              <div
                className="modelText"
                style={{
                  position: "absolute",
                  top: "664px",
                  left: "58%",
                  textAlign: "left",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {props.itemName}
                </p>
              </div>
            )}

            {type === "Pet" && (
              <div
                className="modelText"
                style={{
                  position: "absolute",
                  top: "656px",
                  left: "56%",
                  textAlign: "left",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {props.itemName}
                </p>
              </div>
            )}

            {type === "Planter" && (
              <div
                className="modelText"
                style={{
                  position: "absolute",
                  top: "656px",
                  left: "56%",
                  textAlign: "left",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {props.itemName}
                </p>
              </div>
            )}

            {props.company !== "Cook" && (
              <div className="dateStyle" style={dateStyle}>
                <p style={dateTextStyle}>{new Date()?.toLocaleDateString()}</p>
              </div>
            )}
          </div>
        </>
      );
    }

    if (props.formType === "priceonly") {
      fetchQrCode();

      return (
        <>
          <div style={{ height: "100%", margin: "0" }}>
            <style>{getPageMargins()}</style>
            <div
              ref={ref}
              style={{
                height: "100%",
                padding: "0",
                margin: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div style={{ width: "auto" }}>
                <h5
                  style={{
                    fontSize: "325px",
                    fontWeight: "bold",
                    margin: "0",
                    transform: "scale(1, 1.5)",
                  }}
                >
                  <span style={{ fontSize: "75px", verticalAlign: "175%" }}>
                    $
                  </span>
                  {props.monthly}
                  <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                    /m
                  </span>
                </h5>

                {qrCode && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={qrCode}
                        alt="QR Code"
                        height={"auto"}
                        width={"250px"}
                      />
                      <div
                        style={{
                          position: "absolute",
                          left: "100%", // positions the div to the right of the QR code
                          top: "50%",
                          transform: "translateY(-50%)", // centers the div vertically relative to the QR code
                          marginLeft: "20px",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          SCAN HERE
                        </span>
                        <span
                          style={{
                            display: "block",
                            marginTop: "5px",
                            fontSize: "50px",
                          }}
                        >
                          ←
                        </span>
                        <span
                          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                        >
                          FOR ASSISTANCE
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {/*
                CENTERED QR CODE + TEXT, ORIGINAL DESIGN
                <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        SCAN HERE
                      </span>
                      <span
                        style={{
                          display: "block",
                          marginTop: "5px",
                          fontSize: "50px",
                        }}
                      >
                        →
                      </span>
                      <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        FOR ASSISTANCE
                      </span>
                    </div> */}

                {/*
                CENTERED QR CODE + TEXT ON BOTH SIDES
                <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        SCAN HERE
                      </span>
                      <span
                        style={{
                          display: "block",
                          marginTop: "5px",
                          fontSize: "50px",
                        }}
                      >
                        →
                      </span>
                      <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        FOR ASSISTANCE
                      </span>
                    </div> */}

                {/* 
                  CENTERED QR
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={qrCode}
                        alt="QR Code"
                        height={"auto"}
                        width={"250px"}
                      />
                      <div
                        style={{
                          position: "absolute",
                          left: "100%", // positions the div to the right of the QR code
                          top: "50%",
                          transform: "translateY(-50%)", // centers the div vertically relative to the QR code
                          marginLeft: "20px",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          SCAN HERE
                        </span>
                        <span
                          style={{
                            display: "block",
                            marginTop: "5px",
                            fontSize: "50px",
                          }}
                        >
                          ←
                        </span>
                        <span
                          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                        >
                          FOR ASSISTANCE
                        </span>
                      </div>
                    </div>
                  </div> */}

                <p
                  style={{
                    margin: "0",
                    fontSize: "15px",
                    position: "absolute",
                    top: "92vh",
                    left: "30px",
                  }}
                >
                  {props.itemName} - {props.lot.number}
                </p>
                <p
                  style={{
                    margin: "0",
                    fontSize: "15px",
                    position: "absolute",
                    top: "95vh",
                    left: "30px",
                  }}
                >
                  {new Date()?.toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (props.formType === "sizeonly") {
      return (
        <>
          <style>{getPageMargins()}</style>
          <div
            ref={ref}
            className="print-form"
            style={{
              display: "flex",
              minHeight: "100vh",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <h5
              style={{
                margin: "auto",
                fontSize: "180px",
                fontWeight: "bold",
                width: "100%",
                border: "7px solid black",
                borderRadius: "35px",
                transform:
                  props.size.length > 7
                    ? "rotate(90deg) scaleY(1.5) translateY(80px) translateX(-20px)"
                    : "rotate(90deg) scaleY(1.5)",
                flex: "1",
                whiteSpace: "nowrap",
                padding: "10px",
              }}
            >
              {props.size.startsWith("8") ? (
                <>
                  <span style={{ color: "white", fontSize: "81px" }}>0</span>
                  {props.size}
                  <span style={{ color: "white", fontSize: "81px" }}>0</span>
                </>
              ) : (
                <>{props.size}</>
              )}
            </h5>
            <h5
              style={{
                margin: "auto",
                fontSize: "180px",
                fontWeight: "bold",
                width: "100%",
                border: "7px solid black",
                borderRadius: "35px",
                transform:
                  props.size.length > 7
                    ? "rotate(-90deg) scaleY(1.5) translateY(-180px) translateX(20px)"
                    : "rotate(-90deg) scaleY(1.5) translateY(-20px)",
                flex: "1",
                whiteSpace: "nowrap",
                padding: "10px",
              }}
            >
              {props.size.startsWith("8") ? (
                <>
                  <span style={{ color: "white", fontSize: "81px" }}>0</span>
                  {props.size}
                  <span style={{ color: "white", fontSize: "81px" }}>0</span>
                </>
              ) : (
                <>{props.size}</>
              )}
            </h5>
          </div>
        </>
      );
    }
  }

  return getForm();
});

export default BuildingSignForm;
