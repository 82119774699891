import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ItemDataServices from "../../../services/item.services";

export default function DeleteItemsButton() {
  const deleteItems = async () => {
    const items = await ItemDataServices.getAllItems();

    items
      .filter(
        (value) =>
          value.name === "0" ||
          value.type === "0" ||
          value.name.startsWith("#REF!")
      )
      .map((value) => {
        // var itemName = value.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""); // this is to remove white/blank space at the end of item names which could be added when importing or manually item entry
        ItemDataServices.deleteItem(value.name).then(
          console.log("Deleted: " + value.name)
        );
      });
  };
  return (
    <>
      <Button
        variant="danger"
        size="sm"
        style={{ marginLeft: "5px" }}
        onClick={(e) => deleteItems()}
      >
        Cleanup Items
      </Button>
    </>
  );
}
