import React, { useState } from "react";

export const OtherSignForm = React.forwardRef((props, ref) => {
  const getPageMargins = () => {
    return `
        @page {
            margin: 0px !important;
        } 
        `;
  };

  return (
    <>
      <style>{getPageMargins()}</style>
      <div ref={ref} className="print-form-other" style={{ margin: "15px" }}>
        <img
          src={props.src}
          alt="Simple sign"
          style={{ height: "100%", width: "100%" }}
        />
        {props.formType === "sizeonly" && <h5>Size only</h5>}
        {props.formType === "priceonly" && <h5>Price only</h5>}
      </div>
    </>
  );
});

export default OtherSignForm;
