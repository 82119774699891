import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ViewStates from "../ViewStates";

export default function ListStateModal() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        View states
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="list-state-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="list-state-modal">List States</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewStates />
        </Modal.Body>
      </Modal>
    </>
  );
}
