import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { collection, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { OAuthProvider } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

/**
 * DEPRECATED: This function is not used for logging in, the logging in method is in the Login.js auth page file
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const provider = new OAuthProvider("microsoft.com");

  provider.setCustomParameters({
    tenant: "b9164165-f15e-487c-a26d-0ad1bec60cb8",
  });

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function loginWithPopup() {
    return auth.signInWithPopup(auth, provider).then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
    });
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function getUserDoc(user) {
    console.log("Look up user: " + user.email);
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      if (doc.id === user.uid) {
        console.log("Found match: " + doc.email);
        return doc;
      }
    });
    return null;
  }

  async function setDefaultUserData(user) {
    try {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document found, not setting default.");
      } else {
        console.log("No such document!");
        const docRef = await setDoc(doc(db, "users", user.uid), {
          userId: user.uid,
          email: user.email,
          admin: false,
          lastLogin: new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          }),
        });
        console.log("Document written to database");
        return docRef;
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function isAdmin(user) {
    const documents = await getDocs(collection(db, "users"));
    documents.forEach((document) => {
      if (document.data().userId === user.uid) {
        return document.data().admin;
      }
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  });

  const value = {
    currentUser,
    login,
    loginWithPopup,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    setDefaultUserData,
    getUserDoc,
    isAdmin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
