import { useEffect, useState } from "react";
import LotDataService from "../../services/lot.services";
import {
  Table,
  Spinner,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import Paginator from "../Paginator";
import { BsArrowCounterclockwise, BsSearch } from "react-icons/bs";
import EditLotModal from "./modals/EditLotModal";
import StateDataService from "../../services/state.services";

function ViewLots({ admin, modal }) {
  const [lots, setLots] = useState([]);
  const [states, setStates] = useState([]);
  const [originalLots, setOriginaLots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lotsPerPage] = useState(10);

  useEffect(() => {
    getLots();
    getStates();
  }, []);

  const getLots = async () => {
    setLoading(true);
    const data = await LotDataService.getAllLots();
    console.log("Loaded lots: " + data.length);
    setLots(data);
    setOriginaLots(data);
    setLoading(false);
  };

  const getStates = async () => {
    setLoading(true);
    const data = await StateDataService.getAllStates();
    setStates(data);
    setLoading(false);
  };

  const getStateById = (id) => {
    let state = states.find((state) => state._id === id);
    return state || "";
  };

  const getRegionId = (lot) => {
    // TEMPLATE: 001102
    let stateCode = getStateById(lot.stateId)?.stateCode;
    let storeNumber = lot?.number;

    return `${stateCode}${storeNumber}`;
  };

  const deleteHandler = async (id) => {
    await LotDataService.deleteLot(id);
    getLots();
  };

  const updateLotList = (e) => {
    let previousLots = lots;
    let filteredLots = previousLots.filter((lot) =>
      lot.number.toLowerCase().includes(e)
    );

    if (e === "" || e === " ") {
      filteredLots = originalLots;
    }

    if (filteredLots.length > 0) {
      setLots(filteredLots);
    } else {
      setLots(originalLots);
    }
  };

  const indexOfLastLot = currentPage * lotsPerPage;
  const indexOfFirstLot = indexOfLastLot - lotsPerPage;
  const currentLots = lots.slice(indexOfFirstLot, indexOfLastLot);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div
        className="list"
        style={modal && { width: "75%", left: "10%", marginTop: "0" }}
      >
        <div className="mb-2">
          <Row>
            <Col xs="auto">
              <Button variant="info" size="sm" onClick={getLots}>
                <BsArrowCounterclockwise style={{ marginRight: "3px" }} />
                Refresh List
              </Button>
            </Col>
            <Col xs={8}>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id="basic-addon1">
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search lot..."
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    updateLotList(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        {loading && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Table
          className="text-center shadow-sm"
          striped
          hover
          size={modal ? "sm" : "lg"}
        >
          <thead>
            <tr>
              <th>Lot #</th>
              <th>Brand</th>
              <th>Zone</th>
              <th>State</th>
              <th>Region ID</th>
              {admin && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {currentLots
              .sort((a, b) => {
                return a.number - b.number;
              })
              .map((doc) => {
                return (
                  <tr key={doc._id}>
                    <td>{doc.number}</td>
                    <td>{doc.brand}</td>
                    <td>{doc.zone}</td>
                    <td>{getStateById(doc.stateId)?.name}</td>
                    <td>{getRegionId(doc)}</td>
                    {admin && (
                      <td>
                        {/* check if the user is admin before allowing the delete button */}
                        {admin && (
                          <>
                            <EditLotModal lot={doc} />
                            <Button
                              variant="danger"
                              size="sm"
                              style={{ marginLeft: "5px" }}
                              onClick={(e) => deleteHandler(doc._id)}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Paginator
          itemsPerPage={lotsPerPage}
          totalItems={lots.length}
          paginate={paginate}
          currentPage={currentPage}
        />
        <div>
          {lots.length > lotsPerPage && (
            <Paginator
              itemsPerPage={lotsPerPage}
              totalItems={lots.length}
              paginate={paginate}
              currentPage={currentPage}
              visible={true}
              isDataItems={false}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ViewLots;
