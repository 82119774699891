import { useEffect, useState } from "react";
import UserDataService from "../../services/user.services";
import {
  Table,
  Spinner,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import { BsArrowCounterclockwise, BsSearch } from "react-icons/bs";
import Paginator from "../Paginator";
import EditUserModal from "./modals/EditUserModal";

function ViewUsers({ modal }) {
  const [users, setUsers] = useState([]);
  const [viewedUsers, setViewedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    const data = await UserDataService.getAllUsers();
    console.log("Loaded users: " + data.length);
    setUsers(data);
    setViewedUsers(data);
    setLoading(false);
  };

  const updateUserList = (e) => {
    if (e === "" || e === " ") {
      setViewedUsers(users);
      return;
    }

    let previousUsers = users;
    let filteredUsers = previousUsers.filter((user) =>
      user.email.toLowerCase().includes(e)
    );

    if (filteredUsers.length > 0) {
      setViewedUsers(filteredUsers);
    } else {
      setViewedUsers(users);
    }
  };

  const updateFilter = (e) => {
    let previousUsers = users;
    if (e === "all") {
      setViewedUsers(users);
      return;
    }

    if (e === "admin") {
      previousUsers = previousUsers.filter(
        (value) => value.admin === true || value.admin === "true"
      );
      setViewedUsers(previousUsers);
      return;
    }

    if (e === "default") {
      previousUsers = previousUsers.filter((value) => !value.admin);
      setViewedUsers(previousUsers);
      return;
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = viewedUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div
        className="list"
        style={modal && { width: "75%", left: "10%", marginTop: "0" }}
      >
        <div className="mb-2">
          <Row>
            <Col xs="auto">
              <Button variant="info" size="sm" onClick={getUsers}>
                <BsArrowCounterclockwise style={{ marginRight: "3px" }} />
                Refresh List
              </Button>
            </Col>
            <Col xs={8}>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id="basic-addon1">
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search user..."
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    updateUserList(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col xs="auto">
              <InputGroup className="mb-3" size="sm">
                <Form.Select
                  aria-label="User type select"
                  onChange={(e) => updateFilter(e.currentTarget.value)}
                >
                  <option value="all">All</option>
                  <option value="default">Default</option>
                  <option value="admin">Admin</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
        </div>
        {loading && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Table className="shadow-sm" striped hover size={modal ? "sm" : "lg"}>
          <thead>
            <tr>
              <th>Email Address</th>
              <th>Admin Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((doc) => {
              return (
                <tr key={doc._id}>
                  <td>{doc.email}</td>
                  <td>{"" + doc.admin}</td>
                  <td>
                    <EditUserModal user={doc} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <Paginator
            itemsPerPage={usersPerPage}
            totalItems={users.length}
            paginate={paginate}
            currentPage={currentPage}
            visible={true}
            isDataItems={false}
          />
        </div>
      </div>
    </>
  );
}

export default ViewUsers;
