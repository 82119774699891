import React, { useState } from "react";
import StateDataService from "../../../services/state.services";
import { Alert, Button, Form, Modal } from "react-bootstrap";

export default function AddStateModal() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ error: false, message: "" });

  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [stateCode, setStateCode] = useState("");

  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setMessage({ error: false, message: "" });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || stateCode === "") {
      setMessage({ error: true, message: "All fields are required." });
      return;
    }

    const State = {
      name,
      stateCode,
    };

    try {
      setLoading(true);
      await StateDataService.addState(State);
      setMessage({ error: false, message: "Added new state successfully" });
      setName("");
      setStateCode("");
    } catch (e) {
      setLoading(false);
      setMessage({ error: true, message: e.message });
    }

    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add a state
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add state</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.message && (
            <Alert
              variant={message?.error ? "danger" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4" id="state-name">
              <Form.Label>State name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4" id="state-code">
              <Form.Label>State code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state code"
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              style={{ marginRight: "5px" }}
            >
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
