import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { BsFileEarmarkArrowDown } from "react-icons/bs";

export default function ExportDataButton({ data }) {
  const csvLink = useRef();
  const headers = [
    { label: "name", key: "name" },
    { label: "type", key: "type" },
    { label: "company", key: "company" },
    { label: "size", key: "size" },

    { label: "zone1Cash", key: "zone1Cash" },
    { label: "zone2Cash", key: "zone2Cash" },
    { label: "zone3Cash", key: "zone3Cash" },

    { label: "zone1Card", key: "zone1Card" },
    { label: "zone2Card", key: "zone2Card" },
    { label: "zone3Card", key: "zone3Card" },

    { label: "zone1Msrp", key: "zone1Msrp" },
    { label: "zone2Msrp", key: "zone2Msrp" },
    { label: "zone3Msrp", key: "zone3Msrp" },

    { label: "zone1Monthly", key: "zone1Monthly" },
    { label: "zone2Monthly", key: "zone2Monthly" },
    { label: "zone3Monthly", key: "zone3Monthly" },

    { label: "gvwr", key: "gvwr" },
  ];

  return (
    <>
      <Button
        variant="success"
        size="sm"
        style={{ marginLeft: "5px" }}
        onClick={(e) => csvLink.current.link.click()}
      >
        <BsFileEarmarkArrowDown style={{ marginRight: "3px" }} />
        Export
      </Button>
      <CSVLink
        headers={headers}
        data={data}
        filename={`Simple-Sign-Export-${new Date().toLocaleDateString()}`}
        ref={csvLink}
        target="_blank"
      />
    </>
  );
}
