import { useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import ItemDataServices from "../../../services/item.services";
import { useAuth } from "../../../context/AuthContext";

function AddItemModal() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    resetFields();
  };
  const handleShow = () => setShow(true);

  const auth = useAuth();

  let typeList = ["Building", "Aluminum", "Tow", "Cargo", "Dump", "Utility"];

  const [name, setName] = useState("");
  const [type, setType] = useState("Building");
  const [size, setSize] = useState("");

  // Cash pricing
  const [zone1Cash, setZone1Cash] = useState("");
  const [zone2Cash, setZone2Cash] = useState("");
  const [zone3Cash, setZone3Cash] = useState("");

  // Card Pricing
  const [zone1Card, setZone1Card] = useState("");
  const [zone2Card, setZone2Card] = useState("");
  const [zone3Card, setZone3Card] = useState("");

  // MSRP pricing
  const [zone1Msrp, setZone1Msrp] = useState("");
  const [zone2Msrp, setZone2Msrp] = useState("");
  const [zone3Msrp, setZone3Msrp] = useState("");

  // Monthly pricing
  const [zone1Monthly, setZone1Monthly] = useState("");
  const [zone2Monthly, setZone2Monthly] = useState("");
  const [zone3Monthly, setZone3Monthly] = useState("");

  // GVWR (gross vehicle weight rating)
  const [gvwr, setGvwr] = useState("");

  const [company, setCompany] = useState("");

  const [addedBy, setAddedBy] = useState(auth.currentUser.email);

  const [message, setMessage] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setName("");
    setType("");
    setSize("");
    setZone1Cash("");
    setZone2Cash("");
    setZone3Cash("");
    setZone1Card("");
    setZone2Card("");
    setZone3Card("");
    setZone1Msrp("");
    setZone2Msrp("");
    setZone3Msrp("");
    setZone1Monthly("");
    setZone2Monthly("");
    setZone3Monthly("");
    setGvwr("");
    setCompany("");
    setAddedBy("");
    setMessage(false, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let dateAdded =
      new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();

    const Item = {
      name,
      type,
      size,
      zone1Cash,
      zone2Cash,
      zone3Cash,
      zone1Card,
      zone2Card,
      zone3Card,
      zone1Msrp,
      zone2Msrp,
      zone3Msrp,
      zone1Monthly,
      zone2Monthly,
      zone3Monthly,
      gvwr,
      company,
      dateAdded,
      addedBy,
    };

    try {
      setLoading(true);
      console.log(Item);
      await ItemDataServices.addItem(Item);
      setMessage({ error: false, message: "Added new item successfully" });
      console.log("Added item successfully");
      resetFields();
      handleClose();
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add an item
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.message && (
            <Alert
              variant={message?.error ? "danger" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.message}
            </Alert>
          )}
          <Form>
            <h4 className="text-center">Information</h4>
            <Row>
              <Col>
                <Form.Group className="mb-4" id="item-name">
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4" id="item-type">
                  <Form.Label>Item Type</Form.Label>
                  <Form.Select
                    defaultValue={0}
                    onChange={(e) => setType(typeList[e.target.value])}
                  >
                    {typeList.map((value, index) => {
                      return (
                        <option key={index} value={index}>
                          {value.includes("Building")
                            ? "Building"
                            : value + " Trailer"}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Item Size</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item size"
                    value={size}
                    required
                    onChange={(e) => setSize(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {type !== "Building" && (
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>Item GVWR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Gross vehicle weight rating"
                      value={gvwr}
                      required
                      onChange={(e) => setGvwr(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              )}
              {type === "Building" && (
                <Col xs={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>Company</Form.Label>
                    <Form.Select
                      defaultValue={0}
                      onChange={(e) => setCompany(e.target.value)}
                    >
                      <option key="0" value="0">
                        Company
                      </option>
                      <option key="leonard" value="Leonard">
                        Leonard
                      </option>
                      <option key="cook" value="Cook">
                        Cook
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
            </Row>

            <h4 className="text-center">Pricing</h4>
            {company !== "Cook" && (
              <>
                <Row>
                  <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>Cash</p>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-cash">
                      <Form.Control
                        type="text"
                        placeholder="Cash"
                        value={zone1Cash}
                        required
                        onChange={(e) => setZone1Cash(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* Card Pricing */}
                {/* <Row>
                  <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>Card</p>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-card">
                      <Form.Control
                        type="text"
                        placeholder="Card"
                        value={zone1Card}
                        required
                        onChange={(e) => setZone1Card(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                {/* MSRP Pricing */}
                <Row>
                  {/* <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>MSRP</p>
                  </Col> */}
                  {/* <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-msrp">
                      <Form.Control
                        type="text"
                        placeholder="MSRP"
                        value={zone1Msrp}
                        required
                        onChange={(e) => setZone1Msrp(e.target.value)}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                {/* Monthly Pricing */}
                <Row>
                  <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>Monthly</p>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-monthly">
                      <Form.Control
                        type="text"
                        placeholder="$"
                        value={zone1Monthly}
                        required
                        onChange={(e) => setZone1Monthly(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {company === "Cook" && (
              <>
                <Row>
                  <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>Price</p>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-msrp">
                      <Form.Control
                        type="text"
                        placeholder="List price"
                        value={zone1Msrp}
                        required
                        onChange={(e) => {
                          setZone1Msrp(e.target.value);
                          setZone1Card(0);
                          setZone1Cash(0);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <p style={{ marginTop: "5px" }}>Monthly</p>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-4" id="zone-monthly">
                      <Form.Control
                        type="text"
                        placeholder="Monthly price"
                        value={zone1Monthly}
                        required
                        onChange={(e) => {
                          setZone1Monthly(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Button
              disabled={loading}
              type="submit"
              style={{ marginRight: "5px" }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddItemModal;
