import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListItem from "../../ListItem";

function ListItemModal() {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        View items
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="list-item-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="list-item-modal">List Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListItem modal={true} admin={true} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ListItemModal;
