import React, { useEffect, useState } from "react";
import LotDataService from "../../../services/lot.services";
import StateDataService from "../../../services/state.services";
import { Button, Modal, Form } from "react-bootstrap";

export default function EditLotModal({ lot }) {
  const [show, setShow] = useState(false);
  const [states, setStates] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    fetchStates();
    setShow(true);
  };

  const Lot = {
    number: lot.number || "",
    zone: lot.zone || "",
    stateId: lot.stateId || "",
    brand: lot.brand || "",
  };

  const fetchStates = async () => {
    await StateDataService.getAllStates()
      .then((response) => {
        setStates(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Lot: ", Lot);

    try {
      await LotDataService.updateLot(lot._id, Lot);
    } catch (e) {
      console.log(e.message);
    }

    handleClose();
  };

  return (
    <>
      <Button variant="warning" size="sm" onClick={handleShow}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lot: {lot.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginBottom: "15px" }}>
              <Form.Label>Zone</Form.Label>
              <Form.Control
                type="text"
                defaultValue={lot.zone}
                onChange={(e) => (Lot.zone = e.target.value)}
              />

              <Form.Label style={{ marginTop: "20px" }}>State</Form.Label>
              <Form.Select
                defaultValue={lot.stateId || "Select a state"}
                onChange={(e) => (Lot.stateId = e.target.value)}
              >
                <option key={"none"} value={""}>
                  Select a state
                </option>
                {states.map((state) => {
                  return (
                    <option key={state._id} value={state._id}>
                      {state.name}
                    </option>
                  );
                })}
              </Form.Select>

              <Form.Label style={{ marginTop: "20px" }}>Brand</Form.Label>
              <Form.Select
                defaultValue={lot.brand || "Select a brand"}
                onChange={(e) => (Lot.brand = e.target.value)}
              >
                <option key={"none"} value={""}>
                  Select a brand
                </option>
                <option key={"leonard"} value={"Leonard"}>
                  Leonard
                </option>
                <option key={"cook"} value={"Cook"}>
                  Cook
                </option>
              </Form.Select>
            </Form.Group>
            <Button style={{ marginRight: "5px" }} type="submit">
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
