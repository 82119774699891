import React, { useState } from "react";
import {
  imgStyle,
  sizeStyle,
  sizeTextStyle,
  typeStyle,
  typeTextStyle,
  monthlyStyle,
  monthlyTextStyle,
  msrpStyle,
  msrpTextStyle,
  cardStyle,
  cardTextStyle,
  cashStyle,
  cashTextStyle,
  lotStyle,
  lotTextStyle,
  gvwrStyle,
  gvwrTextStyle,
  itemStyle,
  itemTextStyle,
  westVirginiaStyle,
  dateStyle,
  dateTextStyle,
} from "../styles/StyleSheet";
import StateDataService from "../services/state.services";
import LotDataService from "../services/lot.services";

function currencyFormat(num) {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const SimpleSignForm = React.forwardRef((props, ref) => {
  const [qrCode, setQRCode] = useState(null);

  const getPageMargins = () => {
    return `
    @page {
      margin: 0px !important;
    };
    @media print {
        @page {
            size: portrait !important;
        }
    }`;
  };

  const getLot = async (lotNumber) => {
    const lots = await LotDataService.getAllLots();
    return lots.find((lot) => lot.number === lotNumber);
  };

  const getRegionId = (state, lot) => {
    // TEMPLATE: 001102
    let stateCode = state?.stateCode;
    let storeNumber = lot?.number;

    return `${stateCode}${storeNumber}`;
  };

  const fetchQrCode = async () => {
    const lot = await getLot(props.lot);

    const state = await StateDataService.getState(lot?.stateId).catch((err) =>
      console.error(err)
    );
    const regionId = getRegionId(state, lot);

    if (qrCode) {
      let url = decodeURIComponent(qrCode);
      let params = new URLSearchParams(url);
      let sku = params.get("sku");
      let region = params.get("region_id");

      if (sku == props.itemName && region == regionId) {
        console.log("QR code already exists, skipping...");
        return;
      }
    }

    try {
      console.log("Fetching QR code...");

      const response = await LotDataService.generateQRCodeURL(
        lot?.brand,
        lot?.number,
        regionId,
        props.itemName,
        props.cardPrice?.replace(",", "")?.replace("$", "")?.split(".")[0]
      );

      console.log("QR code response:", decodeURIComponent(response.url));

      setQRCode(response.url);
    } catch (error) {
      console.log("Error fetching QR code:", error);
    }
  };

  fetchQrCode();

  return (
    <>
      <style>{getPageMargins()}</style>
      <div ref={ref} className="print-form" style={{ margin: "15px" }}>
        <img
          // src={require("../images/blank-bob-simple-sign.png")}
          src={require("../images/Leonard-Trailer-Sign.png")}
          alt="Simple Sign"
          style={imgStyle}
        ></img>
        <div className="size" style={sizeStyle}>
          <p style={sizeTextStyle}>{props.size}</p>
        </div>
        <div className="type" style={typeStyle}>
          <h2 style={typeTextStyle}>{props.type}</h2>
        </div>
        <div className="monthly" style={monthlyStyle}>
          <h3 style={monthlyTextStyle}>
            {currencyFormat(props.monthly.split(".")[0].replace("$", ""))}
          </h3>
        </div>
        {/* <div className="msrp" style={msrpStyle}>
          <p style={msrpTextStyle}>${props.msrp.replace("$", "")}</p>
        </div> */}
        <div className="cashPrice" style={cashStyle}>
          <p style={cashTextStyle}>
            $
            {props.cashPrice
              .toString()
              .replace("$", "")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </p>
        </div>
        {props.lot.startsWith("4") && (
          <>
            <div style={westVirginiaStyle}>
              <p></p>
            </div>
          </>
        )}
        <div className="lotText" style={lotStyle}>
          <p style={lotTextStyle}>{props.lot}</p>
        </div>
        <div className="zoneText" style={gvwrStyle}>
          <p style={gvwrTextStyle}>{props.gvwr}</p>
        </div>
        <div className="modelText" style={itemStyle}>
          <p style={itemTextStyle}>{props.itemName}</p>
        </div>
        {qrCode && (
          <div
            className="qrCode"
            style={{
              position: "absolute",
              top: "856px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              fontSize: "0.5rem",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                textAlign: "center",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ marginBottom: "15px" }}>SCAN HERE</div>
              <div style={{ fontSize: "50px", lineHeight: "0" }}>→</div>
              <div style={{ marginTop: "15px" }}>FOR ASSISTANCE</div>
            </div>

            <img src={qrCode} alt="QR Code" height={"auto"} width={"175px"} />

            <div
              style={{
                fontSize: "18px",
                textAlign: "center",
                marginLeft: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ marginBottom: "15px" }}>SCAN HERE</div>
              <div style={{ fontSize: "50px", lineHeight: "0" }}>←</div>
              <div style={{ marginTop: "15px" }}>FOR ASSISTANCE</div>
            </div>
          </div>
        )}

        {props.company !== "Cook" && (
          <div className="dateText" style={dateStyle}>
            <p style={dateTextStyle}>{new Date()?.toLocaleDateString()}</p>
          </div>
        )}
      </div>
    </>
  );
});

export default SimpleSignForm;
