// API
import axios from "axios";

const endpoint = "https://dev-2.leonardusa.com/api/internal/simplesigns/users";

const api = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "username",
    password: "password",
  },
});

class UserDataService {
  addUser = async (newUser) => {
    console.log("Ran adduser");
    return await api
      .post("/", newUser)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  updateUser = async (id, updatedUser) => {
    return await api
      .patch(`/${id}`, updatedUser)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  deleteUser = async (id) => {
    return await api
      .delete(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getAllUsers = async () => {
    return await api
      .get("/")
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getUser = async (email) => {
    return await api
      .get(`/${email}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  isAdmin = async (email) => {
    const data = await this.getUser(email);
    return data[0].admin;
  };
}

export default new UserDataService();
