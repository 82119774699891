import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Overlay } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import OtherSignForm from "../forms/OtherSignForm";
import SorryWeMissedYouForm from "../forms/SorryWeMissedYouForm";
import LotDataService from "../services/lot.services.js";
import StateDataService from "../services/state.services.js";

function PageItem({ title, description, src, lot, type }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const target = useRef(null);
  const [error, setError] = useState("");

  const [qrCode, setQrCode] = useState("");

  const getRegionId = (state, lot) => {
    // TEMPLATE: 001102
    let stateCode = state?.stateCode;
    let storeNumber = lot?.number;

    return `${stateCode}${storeNumber}`;
  };

  useEffect(() => {
    if (qrCode && type === "sorry-we-missed-you") {
      handlePrint();
    }
  }, [qrCode, type]);

  useEffect(() => {
    if (lot && lot !== "") {
      setError("");
    }
  }, [lot]);

  const fetchQrCode = async () => {
    if (!lot || lot == "") return;

    const lotObj = await LotDataService.getLot(lot).catch((err) =>
      console.error(err)
    );

    const state = await StateDataService.getState(lotObj?.stateId).catch(
      (err) => console.error(err)
    );

    const regionId = getRegionId(state, lotObj);
    if (qrCode) {
      let url = decodeURIComponent(qrCode);
      let params = new URLSearchParams(url);
      let region = params.get("region_id");
      if (region == regionId) {
        console.log("QR code already exists, skipping...");
        return;
      }
    }
    try {
      console.log("Fetching QR code...");
      const response = await LotDataService.generateSorryWeMissedYouQRCodeURL(
        lotObj?.brand,
        lotObj?.number,
        regionId
      );
      console.log("QR code response:", decodeURIComponent(response.url));

      setQrCode(response.url);
    } catch (error) {
      console.log("Error fetching QR code:", error);
    }
  };

  const handleSubmit = async () => {
    if (lot === "" || !lot) {
      setError("You must select a lot");
      return;
    }

    if (type === "sorry-we-missed-you") {
      await fetchQrCode();
    } else {
      handlePrint();
    }
  };

  return (
    <>
      <Card
        className="page-item"
        border="warning"
        style={{
          margin: "0",
          padding: "0",
          marginTop: "15px",
          textAlign: "center",
        }}
      >
        <Card.Img
          variant="top"
          src={src}
          style={{ width: "10vw", height: "auto", margin: "auto" }}
        />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
          <Button ref={target} variant="primary" onClick={handleSubmit}>
            Select
          </Button>

          {error && (
            <Overlay target={target.current} show={error} placement="right">
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  {...props}
                  style={{
                    position: "absolute",
                    backgroundColor: "#ee5253",
                    padding: "2px 10px",
                    color: "white",
                    borderRadius: 3,
                    ...props.style,
                  }}
                >
                  {error}
                </div>
              )}
            </Overlay>
          )}

          <div style={{ display: "none" }}>
            {type === "sorry-we-missed-you" ? (
              <SorryWeMissedYouForm
                ref={componentRef}
                src={src}
                lot={lot}
                qrCode={qrCode}
              />
            ) : (
              <OtherSignForm ref={componentRef} src={src} />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PageItem;
