import React from "react";
import { imgStyle, qrCodeStyle } from "../styles/SorryWeMissedYouSheet.js";

export const SorryWeMissedYouForm = React.forwardRef((props, ref) => {
  const getPageMargins = () => {
    return `
        @page {
            margin: 0px !important;
        }
        @media print {
            @page {
                size: portrait !important;
            }
        }
        `;
  };

  return (
    <>
      <style>{getPageMargins()}</style>
      <div ref={ref} className="print-form" style={{ margin: "15px" }}>
        <img src={props.src} alt="Sorry we missed you" style={imgStyle} />

        {props.qrCode && (
          <div className="qrCode" style={qrCodeStyle}>
            <img
              src={props.qrCode}
              alt="QR Code"
              height={"auto"}
              width={"425px"}
            />
          </div>
        )}
      </div>
    </>
  );
});

export default SorryWeMissedYouForm;
