import axios from "axios";

const endpoint = "https://dev-2.leonardusa.com/api/internal/simplesigns/items";

const api = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "username",
    password: "password",
  },
});

class ItemDataService {
  addItem = async (newItem) => {
    return await api
      .post("/", newItem)
      .then(({ data }) => data)
      .then(console.log(`Added item: ${newItem.name}`))
      .catch((err) => console.log(err));
  };

  updateItem = async (id, updatedItem) => {
    return await api
      .patch(`/${id}`, updatedItem)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  deleteItem = async (name) => {
    return await api
      .delete(`/name/${name}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  deleteItemById = async (id) => {
    return await api
      .delete(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getAllItems = async () => {
    return await api
      .get("/")
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getItem = async (id) => {
    return await api
      .get(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  /**
   * Imports data using the API
   *
   * @param {*} file -- CSV file which includes the necessary data
   */
  importData = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return await api
      .post("/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data)
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  };
}

export default new ItemDataService();
