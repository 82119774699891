import axios from "axios";

const endpoint =
  "https://dev-2.leonardusa.com/api/internal/simplesigns/lotstates";

const api = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "username",
    password: "password",
  },
});

class StateDataService {
  addState = async (newState) => {
    return await api
      .post("/", newState)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  updateState = async (id, updatedState) => {
    return await api
      .patch(`/${id}`, updatedState)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  deleteState = async (id) => {
    return await api
      .delete(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getAllStates = async () => {
    return await api
      .get("/")
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getState = async (id) => {
    return await api
      .get(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };
}

export default new StateDataService();
