import { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import LotDataServices from "../../../services/lot.services";
import StateDataServices from "../../../services/state.services";

function AddItemModal() {
  const [number, setNumber] = useState("");
  const [zone, setZone] = useState("");
  const [stateId, setStateId] = useState("");
  const [states, setStates] = useState([]);
  const [brand, setBrand] = useState("");
  const [message, setMessage] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setMessage({ error: false, message: "" });
  };

  const handleShow = async () => {
    setShow(true);
    setZone(1);
    fetchStates();
  };

  const fetchStates = async () => {
    await StateDataServices.getAllStates()
      .then((response) => {
        setStates(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (number === "" || zone === "") {
      setMessage({ error: true, message: "All fields are required." });
      return;
    }

    const Lot = {
      number,
      zone,
      stateId,
      brand,
    };

    try {
      setLoading(true);
      await LotDataServices.addLot(Lot);
      setMessage({ error: false, message: "Added new lot successfully" });
      setNumber("");
      setZone("");
    } catch (e) {
      setLoading(false);
      setMessage({ error: true, message: e.message });
    }
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add a lot
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add lot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.message && (
            <Alert
              variant={message?.error ? "danger" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4" id="lot-name">
              <Form.Label>Lot Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Lot number"
                value={number}
                required
                onChange={(e) => setNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4" id="state">
              <Form.Label>State</Form.Label>
              <Form.Select
                defaultValue={""}
                onChange={(e) => setStateId(e.target.value)}
              >
                <option value={""}>Select a state</option>
                {states.map((state) => (
                  <option key={state._id} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" id="brand">
              <Form.Label>Brand</Form.Label>
              <Form.Select
                defaultValue={1}
                onChange={(e) => setBrand(e.target.value)}
              >
                <option key={"leonard"} value={"Leonard"}>
                  Leonard
                </option>
                <option key={"cook"} value={"Cook"}>
                  Cook
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" id="zone">
              <Form.Label>Zone</Form.Label>
              <Form.Select
                defaultValue={1}
                onChange={(e) => setZone(e.target.value)}
              >
                <option key={"1"} value={"1"}>
                  1
                </option>
                <option key={"2"} value={"2"}>
                  2
                </option>
                <option key={"3"} value={"3"}>
                  3
                </option>
              </Form.Select>
            </Form.Group>
            <Button
              disabled={loading}
              type="submit"
              style={{ marginRight: "5px" }}
            >
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddItemModal;
