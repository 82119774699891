import { useState, useRef } from "react";
import { Modal, Alert, Button, Form } from "react-bootstrap";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import ItemDataService from "../../../services/item.services.js";

function ImportDataModal() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setMessage({ error: false, message: "" });
    setShow(false);
    setFile(null);
  };
  const handleShow = () => setShow(true);

  const [message, setMessage] = useState({ error: false, message: "" });
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    if (files[0]?.type !== "text/csv") {
      setMessage({ error: true, message: "You can only import csv files." });
      setFile(null);
      return;
    }

    setFile(files[0]);
    console.log("File input change");
    console.log(files[0]?.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Clicked submit");
    try {
      const data = await ItemDataService.importData(file);
      console.log("Data");
      console.log(data);
      setMessage({ error: false, message: data.message });
    } catch (err) {
      setMessage({ error: true, message: err.message });
    }
  };

  return (
    <>
      <Button variant="success" size="sm" onClick={handleShow}>
        <BsFileEarmarkArrowUp style={{ marginRight: "3px" }} />
        Import
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Import Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message?.message && (
            <Alert
              variant={message?.error ? "danger" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.message}
            </Alert>
          )}
          <Button
            variant="warning"
            size="sm"
            style={{ marginBottom: "10px" }}
            href={require("../../../files/SimpleSignTemplate.xlsx")}
            download="SimpleSignTemplate"
          >
            Download Template
          </Button>
          <Form.Group controlId="formFile">
            <Form.Control
              type="file"
              onChange={onFileInputChange}
              ref={fileInputRef}
            ></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Import File
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportDataModal;
