// This is the JSX CSS style sheet for the building signs

export const imgStyle = {
  height: "100%",
  width: "100%",
};

export const sizeStyle = {
  position: "absolute",
  top: "160px",
  width: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const qrCodeStyle = {
  position: "absolute",
  top: "98px",
  left: "0",
  width: "0px",
  height: "auto",
  zIndex: "0",
};

export const qrCodeImageStyle = {};

export const sizeTextStyle = {
  fontSize: "90px",
  fontWeight: "bold",
  textAlign: "center",
};

export const typeStyle = {
  position: "absolute",
  top: "255px",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const typeTextStyle = {
  fontSize: "65px",
  fontWeight: "bold",
  textAlign: "center",
};

export const typeSmallStyle = {
  position: "absolute",
  top: "258px",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const typeSmallTextStyle = {
  fontSize: "40px",
  fontWeight: "bold",
  textAlign: "center",
};

export const monthlyStyle = {
  position: "absolute",
  top: "440px",
  left: "47%",
  transform: "translate(-50%, -50%)",
};

export const monthlyTextStyle = {
  fontSize: "180px",
  fontWeight: "bold",
  textAlign: "center",
  color: "#000000",
  textShadow: "3px 3px #7f8c8d",
};

export const msrpTextStyle = {
  fontSize: "20px",
  textAlign: "center",
  fontWeight: "bold",
  fontStyle: "italic",
  textDecoration: "line-through",
};

export const msrpStyle = {
  position: "absolute",
  top: "676px",
  left: "14%",
  transform: "translate(-50%, -50%)",
};

export const cardStyle = {
  position: "absolute",
  top: "676px",
  left: "38%",
  transform: "translate(-50%, -50%)",
};

export const cardTextStyle = {
  fontSize: "20px",
  textAlign: "left",
  fontWeight: "bold",
};

export const cashStyle = {
  position: "absolute",
  top: "710px",
  left: "33%",
  transform: "translate(-50%, -50%) translateY(30px)",
  marginBottom: "5px",
};

export const cashTextStyle = {
  fontSize: "60px",
  color: "black",
  textAlign: "center",
  fontWeight: "bold",
};

export const lotStyle = {
  position: "absolute",
  top: "695px",
  left: "60%",
  transform: "translate(-50%, -50%)",
};

export const lotTextStyle = {
  fontSize: "14px",
};

export const petCoopLotStyle = {
  position: "absolute",
  top: "670px",
  left: "40%",
  transform: "translate(-50%, -50%)",
};

export const gvwrStyle = {
  position: "absolute",
  top: "969px",
  left: "41%",
  transform: "translate(-50%, -50%)",
};

export const gvwrTextStyle = {
  fontSize: "14px",
};

export const cookItemStyle = {
  position: "absolute",
  top: "696px",
  left: "82%",
  textAlign: "left",
  transform: "translate(-50%, -50%)",
};

export const cookBigItemStyle = {
  position: "absolute",
  top: "696px",
  left: "86%",
  textAlign: "left",
  transform: "translate(-50%, -50%)",
};

export const cookBigItemTextStyle = {
  fontSize: "11px",
  textAlign: "left",
};

export const cookItemTextStyle = {};

export const itemStyle = {
  position: "absolute",
  top: "695px",
  left: "83%",
  textAlign: "left",
  transform: "translate(-50%, -50%)",
};

export const itemTextStyle = {
  fontSize: "14px",
  textAlign: "left",
};

export const sizeOnlyStyle = {
  position: "absolute",
  margin: "0 auto",
  top: "35%",
  left: "35%",
  textAlign: "center",
};

export const priceOnlyStyle = {};

export const dateStyle = {
  position: "absolute",
  top: "800px",
  left: "50%",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
};

export const datePriceStyle = {
  position: "absolute",
  top: "620px",
  left: "50%",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
};

export const dateTextStyle = {
  fontSize: "14px",
  textAlign: "left",
};

/**
 * COOP STYLING
 */
export const coopCashPriceStyle = {
  position: "absolute",
  top: "440px",
  left: "65%",
  transform: "translate(-50%, -50%)",
};

export const coopCashPriceTextStyle = {
  fontSize: "150px",
  fontWeight: "bold",
  textAlign: "left",
  color: "#000000",
  textShadow: "3px 3px #7f8c8d",
};

export const coopMsrpTextStyle = {
  fontSize: "62px",
  textAlign: "left",
  fontWeight: "bold",
  fontStyle: "italic",
  textDecoration: "line-through",
  color: "#000000",
};

export const coopMsrpStyle = {
  position: "absolute",
  top: "625px",
  left: "62%",
  transform: "translate(-50%, -50%)",
};

export const coopCardPriceStyle = {
  // position: "absolute",
  // top: "712px",
  // left: "35%",
  // transform: "translate(-50%, -50%)",
  // textAlign: "right",
  position: "absolute",
  top: "710px",
  left: "33%",
  transform: "translate(-50%, -50%) translateY(30px)",
  marginBottom: "5px",
};

export const coopCardPriceTextStyle = {
  fontSize: "70px",
  color: "black",
  textAlign: "right",
  fontWeight: "bold",
};

/**
 * END COOP STYLING
 */

export default StyleSheet;
