import React from "react";
import { Navigate, useLocation, Routes } from "react-router-dom";
import { auth } from "../firebase";

const PrivateRoute = ({ children }) => {
  let isAuthenticated = auth.currentUser;

  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname }}
        replace={true}
      />
    );
  }

  const width =
    location.pathname === "/admin" || location.pathname === "/"
      ? "100%"
      : "100vw";

  return (
    <div style={{ width: width, minHeight: "100vh" }}>
      <Routes>{children}</Routes>
    </div>
  );
};

export default PrivateRoute;
