import { Routes } from "react-router-dom";

const PublicRoute = ({ children }) => {
  return (
    <div style={{ width: "400px", minHeight: "100vh" }}>
      <Routes>{children}</Routes>
    </div>
  );
};

export default PublicRoute;
