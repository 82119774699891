import { useState, useRef } from "react";
import {
  Form,
  Modal,
  Button,
  Col,
  Row,
  InputGroup,
  Overlay,
} from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import BuildingSignForm from "../forms/BuildingSignForm";
import SimpleSignForm from "../forms/SimpleSignForm";

function ItemSelectModal({
  inModal,
  item,
  lot,
  zoneNumber,
  company,
  buildingType,
}) {
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!item || !lot || !zoneNumber) {
      setError("Please make sure you have a lot selected.");
      if (inModal) {
        alert("Please make sure you have a lot selected.");
        return;
      }
      setTimeout(() => {
        setError("");
      }, "1500");
      return;
    }

    let replaced = item.name.replace(/[^A-Za-z ]/g, "");

    const loadPrice = () => {
      let number = zoneNumber.toString();
      setCardPrice(item.zone1Cash.replace("$", "")); // got rid of card price, need to remove it completely
      setCashPrice(item.zone1Cash.replace("$", ""));
      setMsrp(item.zone1Msrp.replace("$", ""));
      setMonthly(item.zone1Monthly.replace("$", ""));

      if (item.type.includes("Building")) {
        setType(item.type);
      } else if (item.type.includes("Trailer")) {
        setType(item.type);
      } else {
        setType(item.type + " Trailer");
      }
    };

    loadPrice();
    setFormType("everything");
    setShow(true);
  };

  const [formType, setFormType] = useState("everything");

  const [cardPrice, setCardPrice] = useState(0);
  const [cashPrice, setCashPrice] = useState(0);
  const [msrp, setMsrp] = useState(item.msrp);
  const [monthly, setMonthly] = useState(item.monthly);
  const [type, setType] = useState("");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getForm(ref, itemType, formType, buildingType) {
    if (itemType.includes("Building")) {
      // Debugging
      // console.log("Form type: " + formType);
      // console.log("Item type: " + buildingType);
      // console.log("Lot #: " + lotNumber);
      return (
        <BuildingSignForm
          ref={ref}
          lot={lot}
          size={item.size}
          gvwr={item.gvwr}
          company={item.company}
          itemName={item.name}
          type={type}
          msrp={msrp}
          monthly={monthly}
          cardPrice={cardPrice}
          cashPrice={cashPrice}
          formType={formType}
          buildingType={buildingType}
        />
      );
    }

    if (!itemType.includes("Building")) {
      return (
        <div style={{ display: "none" }}>
          <SimpleSignForm
            ref={ref}
            lot={lot?.number}
            size={item.size}
            gvwr={item.gvwr}
            itemName={item.name}
            type={type}
            msrp={msrp}
            monthly={monthly}
            cardPrice={cardPrice}
            cashPrice={cashPrice}
          />
        </div>
      );
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePrint();
  };

  return (
    <>
      <Button variant="success" ref={target} size="sm" onClick={handleShow}>
        Select
      </Button>
      {error && (
        <Overlay target={target.current} show={error} placement="right">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                position: "absolute",
                backgroundColor: "#ee5253",
                padding: "2px 5px",
                marginLeft: "15px",
                marginBottom: "10px",
                color: "white",
                borderRadius: 2,
                ...props.style,
              }}
            >
              You must select a lot
            </div>
          )}
        </Overlay>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="confirm-item-input">
              <Row>
                {company !== "Cook" && (
                  <>
                    <h5>Lot #: {lot?.number}</h5>
                    {/* <Col xs={9}>
                      <h5>Lot #: {lot?.number}</h5>
                    </Col>
                    <Col xs="auto">
                      <h5>Zone #: {zoneNumber}</h5>
                    </Col> */}
                  </>
                )}
              </Row>
            </Form.Group>
            {type === "Building" && (
              <>
                <Form.Label>Form Type</Form.Label>
                <Form.Select
                  onChange={(e) => setFormType(e.currentTarget.value)}
                  style={{
                    width: "40%",
                    marginBottom: "20px",
                    marginTop: "0px",
                  }}
                >
                  <option key="everything" value="everything">
                    Everything
                  </option>
                  <option key="priceonly" value="priceonly">
                    Price Only
                  </option>
                  <option key="sizeonly" value="sizeonly">
                    Size Only
                  </option>
                </Form.Select>
              </>
            )}

            {item.company !== "Cook" ? (
              <>
                <Row>
                  <Col>
                    <Form.Label>Price</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Cash Price"
                        defaultValue={cashPrice}
                        required
                        onChange={(e) => setCashPrice(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                  {/* <Col>
                    <Form.Label>MSRP</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="MSRP"
                        defaultValue={msrp}
                        required
                        onChange={(e) => setMsrp(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col> */}
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Monthly</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Monthly"
                        defaultValue={monthly}
                        required
                        onChange={(e) => setMonthly(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Form.Label>List Price</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="List Price"
                        defaultValue={msrp}
                        required
                        onChange={(e) => setMsrp(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <Form.Label>Monthly</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Monthly"
                        defaultValue={monthly}
                        required
                        onChange={(e) => setMonthly(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            )}

            <div style={{ display: "none" }}>
              {getForm(componentRef, type, formType, buildingType)}
            </div>
            <Button style={{ marginRight: "5px" }} onClick={handleSubmit}>
              Print
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemSelectModal;
