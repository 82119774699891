import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ViewUsers from "../ViewUsers";

function ListUsersModal() {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        View users
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="list-lot-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="list-lot-modal">List Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewUsers modal={true} admin={true} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ListUsersModal;
