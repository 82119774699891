let leonardBuildingTypes = [
  { type: "Dog Kennel", name: "CONDO" },
  { type: "Saltbox", name: "HIRK" },
  { type: "Vinyl A-Frame", name: "HMP2" },
  { type: "Lean To", name: "LNTO" },
  { type: "Barn", name: "MBY1" },
  { type: "Mini Barn", name: "MNBE" },
  { type: "Playhouse MNSB", name: "MNSB" },
  { type: "Wood A-Frame", name: "PDM1" },
  { type: "Playhouse PLBG", name: "PLBG" },
  { type: "Playhouse PLCC", name: "PLCC" },
  { type: "Metal Economy", name: "PLTE" },
  { type: "Golf Cart Ready", name: "PLTG" },
  { type: "Premium Metal", name: "PLTS" },
  { type: "Metal Sided Wood Framed", name: "PLTW" },
  { type: "Barn", name: "BARN" },
  { type: "Utility", name: "UTLY" },
  { type: "Garden Shed", name: "GRDN" },
  { type: "Tool Shed", name: "TOOL" },
  { type: "Handyman", name: "HNDY" },
  { type: "Barn Garage", name: "BGAR" },
  { type: "Utility Garage", name: "UGAR" },
  { type: "Slim Shed", name: "SLIM" },
];

let cookBuildingTypes = [
  { type: "Barn", name: "Barn" },
  { type: "Lofted Barn", name: "Lofted Barn" },
  { type: "Utility", name: "Utility" },
  { type: "Lofted Utility", name: "Lofted Utility" },
  { type: "Garage", name: "Garage" },
  { type: "Lofted Barn Cabin", name: "Standard Cabin - Lofted Barn Style" },
  {
    type: "Lofted Barn Premium Cabin",
    name: "Premium Cabin - Lofted Barn Style",
  },
  { type: "Cabin", name: "Standard Cabin - Utility Style" },
  { type: "Utility Premium Cabin", name: "Premium Cabin" },
  { type: "Lofted Barn Handyman", name: "Handyman" },
  { type: "Garden Shed", name: "Garden Shed" },
  { type: "Tool Shed", name: "Tool Shed" },
  { type: "Slim Shed", name: "Slim Shed" },
  { type: "Lofted Utility Handyman", name: "Handyman - Lofted Barn" },
  { type: "Lofted Utility Handyman", name: "Handyman - Lofted Utility" },
  { type: "Chicken Coop", name: "Coop" },
];

class BuildingDataService {
  getLeonardTypes = () => {
    return leonardBuildingTypes;
  };

  getCookTypes = () => {
    return cookBuildingTypes;
  };

  getAllTypes = () => {
    return [...leonardBuildingTypes, ...cookBuildingTypes];
  };

  getBuildingTypeByItem = (item) => {
    if (item?.type !== "Building") {
      return null;
    }

    if (
      !item.company ||
      item.company === null ||
      item.company === "Leonard" ||
      item.company === "" ||
      item.company === 0 ||
      item.company === "0"
    ) {
      return this.getLeonardTypes().filter(({ type, name }) =>
        item.name.includes(name)
      )[0];
    }

    if (item.company === "Cook") {
      let itemName = item.name.replace(/[^A-Za-z ]/g, "");
      let results = this.getCookTypes().filter(({ type, name }) =>
        itemName.includes(name)
      );
      return results[results.length - 1];
    }
  };
}

export default new BuildingDataService();
