// This is the JSX CSS style sheet for the trailer signs

export const imgStyle = {
  height: "100%",
  width: "100%",
};

export const sizeStyle = {
  position: "absolute",
  top: "225px",
  width: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const sizeTextStyle = {
  fontSize: "100px",
  fontWeight: "bold",
  textAlign: "center",
};

export const typeStyle = {
  position: "absolute",
  top: "308px",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const typeTextStyle = {
  fontSize: "50px",
  fontWeight: "bold",
  textAlign: "center",
};

export const monthlyStyle = {
  position: "absolute",
  top: "460px",
  left: "45%",
  transform: "translate(-50%, -50%)",
};

export const monthlyTextStyle = {
  fontSize: "180px",
  fontWeight: "bold",
  textAlign: "center",
  color: "#000000",
  textShadow: "3px 3px #7f8c8d",
};

export const msrpTextStyle = {
  fontSize: "25px",
  textAlign: "center",
  fontStyle: "italic",
  textDecoration: "line-through",
};

export const msrpStyle = {
  position: "absolute",
  top: "700px",
  left: "60%",
  transform: "translate(-50%, -50%)",
};

export const cardStyle = {
  position: "absolute",
  top: "738px",
  left: "60%",
  transform: "translate(-50%, -50%)",
};

export const cardTextStyle = {
  fontSize: "25px",
  textAlign: "left",
  fontWeight: "bold",
};

export const cashStyle = {
  position: "absolute",
  top: "740px",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const cashTextStyle = {
  fontSize: "85px",
  color: "black",
  textAlign: "center",
  fontWeight: "bold",
};

export const lotStyle = {
  position: "absolute",
  top: "969px",
  left: "22%",
  transform: "translate(-50%, -50%)",
};

export const lotTextStyle = {
  fontSize: "14px",
};

export const gvwrStyle = {
  position: "absolute",
  top: "969px",
  left: "42%",
  transform: "translate(-50%, -50%)",
};

export const gvwrTextStyle = {
  fontSize: "14px",
};

export const itemStyle = {
  position: "absolute",
  top: "969px",
  left: "74%",
  textAlign: "left",
  transform: "translate(-50%, -50%)",
};

export const itemTextStyle = {
  fontSize: "14px",
  textAlign: "left",
};

export const westVirginiaStyle = {
  position: "absolute",
  top: "650px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  width: "650px",
  height: "35px",
};

export const dateStyle = {
  position: "absolute",
  top: "1025px",
  left: "50%",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
};

export const dateTextStyle = {
  fontSize: "14px",
  textAlign: "left",
};

export default StyleSheet;
