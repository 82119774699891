import React, { useEffect, useState } from "react";
import PageItem from "../components/PageItem";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import LotDataService from "../services/lot.services.js";

export default function OtherSigns() {
  const [lot, setLot] = useState("");
  const [lots, setLots] = useState([]);

  useEffect(() => {
    const fetchLots = async () => {
      await LotDataService.getAllLots()
        .then((response) => {
          setLots(
            response.map((lot) => ({
              key: lot._id,
              value: lot._id,
              label: lot.number,
            }))
          );
        })
        .catch((e) => console.error(e));
    };

    fetchLots();
  }, []);

  return (
    <>
      <h1 style={{ marginTop: "100px", textAlign: "center" }}>Select a Sign</h1>
      <Container>
        <p style={{ marginBottom: 0, paddingBottom: 0 }}>Select a lot</p>
        <div style={{ width: "15vw" }}>
          <Select options={lots} onChange={(e) => setLot(e.value)} />
        </div>

        <br />

        <Row>
          <Col xs={12} md={4}>
            <PageItem
              title="Leonard - Sorry We Missed You"
              description="Place this on your door if you have to close temporarily."
              src={require("../images/sorry-we-missed-you-leonard.png")}
              lot={lot}
              type="sorry-we-missed-you"
            />
          </Col>
          <Col xs={12} md={4}>
            <PageItem
              title="Cook - Sorry We Missed You"
              description="Place this on your door if you have to close temporarily."
              src={require("../images/sorry-we-missed-you-cook.png")}
              lot={lot}
              type="sorry-we-missed-you"
            />
          </Col>
          {/* <Col xs={12} md={4}>
            <PageItem
              title="Sold Sign"
              description="Place this on a product that has been sold."
              src={require("../images/old-building-simple-sign.jpg")}
            />
          </Col>
          <Col xs={12} md={4}>
            <PageItem
              title="Repo Sign"
              description="Place this on a repossessed product."
              src={require("../images/old-building-simple-sign.jpg")}
            />
          </Col> */}
        </Row>
      </Container>
    </>
  );
}
