import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import UserDataService from "../../../services/user.services";

function EditUserModal({ user }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [adminStatus, setAdminStatus] = useState(false);

  const User = {
    email: user.email,
    admin: adminStatus,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await UserDataService.updateUser(user._id, User);
    } catch (e) {
      console.log(e.message);
    }

    handleClose();
  };

  useEffect(() => {
    setAdminStatus(user.admin);
  }, [user.admin]);

  return (
    <>
      <Button variant="warning" size="sm" onClick={handleShow}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User: {user.email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginBottom: "15px" }}>
              <Form.Label>Admin Status</Form.Label>
              <Form.Select
                defaultValue={user.admin}
                onChange={(e) => setAdminStatus(e.target.value)}
              >
                <option>true</option>
                <option>false</option>
              </Form.Select>
            </Form.Group>

            <Button style={{ marginRight: "5px" }} type="submit">
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default EditUserModal;
